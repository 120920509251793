const fetchData = (sectionNr, expectedImagesCount, country, imagesSize = 'large') => {
    return new Promise((resolve, reject) => {

        const url = country 
            ? `https://gorilo.pl/blog/wp-json/wp/v2/posts?filter[tag]=content_page+post_img+section_${sectionNr}+${country}` 
            : `https://gorilo.pl/blog/wp-json/wp/v2/posts?filter[tag]=content_home+section_${sectionNr}+post_img`;

        fetch(url)
            .then(response => response.json())
            .then(data => {

                const postsDataWithImages = [];
                const postsDataWithoutImages = [];

                for (let post of data) {
                    if (postsDataWithImages.length === expectedImagesCount) {
                        resolve(postsDataWithImages);
                        break;
                    }

                    if (post.acf.section_1_post_img_image) {
                        postsDataWithImages.push({
                            title: post.section_1_post_img_title || post.title.rendered,
                            imageUrl: post.acf.section_1_post_img_image.sizes[imagesSize],
                            alt: post.acf.section_1_post_img_image.alt,
                            link: post.link
                        });
                    } else {
                        postsDataWithoutImages.push(post);
                    }
                }

                const promises = [];
                for (let post of postsDataWithoutImages) {
                    if (promises.length === expectedImagesCount - postsDataWithImages)
                        break;

                    const mediaAddress = post._links['wp:featuredmedia'][0].href;
                    promises.push(new Promise((res, rej) => {
                        fetch(mediaAddress)
                            .then(response => response.json())
                            .then(media => res(media));
                    }));
                }

                Promise.all(promises)
                    .then(results => {

                        for (let i = 0; i < results.length; i++) {
                            postsDataWithImages.push({
                                title: postsDataWithoutImages[i].section_1_post_img_title || postsDataWithoutImages[i].title.rendered,
                                imageUrl: results[i].media_details.sizes.full.source_url,
                                alt: results[i].alt_text,
                                link: postsDataWithoutImages[i].link
                            });
                        }

                        resolve(postsDataWithImages);
                    });
            });
    });
};

const fetchLinks = (country) => {
    const url = country 
            ? `https://gorilo.pl/blog/wp-json/wp/v2/posts?filter[tag]=content_page+post_link+section_1+${country}` 
            : `https://gorilo.pl/blog/wp-json/wp/v2/posts?filter[tag]=content_home+section_1+post_link`;

    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const posts = [];
                for (let post of data) {
                    if (posts.length === 4) break;

                    posts.push({
                        title: post.acf.section_1_post_link_title || post.title.rendered,
                        link: post.link
                    });
                }

                resolve(posts);
            });
    });
};