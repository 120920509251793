(function(document, window){

    function WHCreateCookie(name, value, days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = name+"="+value+expires+"; path=/";
    }
    function WHReadCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    
    window.onload = WHCheckCookies;
    
    function WHCheckCookies() {
        if(WHReadCookie('cookies_accepted') !== 'T') {
          var message_container = document.createElement('div');
          message_container.id = 'cookies-message-container';
          var html_code = '' +
          '<div id="cookies-message" style="padding: 10px 0px; font-size: 14px; line-height: 22px; text-align: center; position: fixed; bottom: 0; background-color: #f3f5f5; width: 100%; z-index: 9999999;">\n' +
            'Nasze strony używają plików cookies. Więcej informacji w naszej polityce cookies. Korzystając ze strony wyrażasz zgodę na używanie Cookies, zgodnie z aktualnymi ustawieniami przeglądarki. W programie służącym do obsługi internetu można zmienić ustawienia dotyczące cookies. \n' +
            '<a href="javascript:WHCloseCookiesWindow();" id="accept-cookies-checkbox" name="accept-cookies" style="background-color: #bdccd4; padding: 5px 10px; color: black; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;">rozumiem</a>\n'
          + '</div>';
          message_container.innerHTML = html_code;
          document.body.appendChild(message_container);
        }
    }
    
    window.WHCloseCookiesWindow = function() {
        WHCreateCookie('cookies_accepted', 'T', 365);
        document.getElementById('cookies-message-container').removeChild(document.getElementById('cookies-message'));
    }
    })(document, window);
    