const firstPostBlocks = document.querySelectorAll('.section2 .post-blocks > a');
const postsLinks = document.querySelectorAll('.section2 .post-blocks ul a');

const secondPostBlocks = document.querySelectorAll('.section4 .post-blocks > a');

const postBlocks = document.querySelectorAll('.blocks.post-blocks .block');
// const coloredBlocks = document.querySelectorAll('.blocks.colored-blocks .block');

const blocksConfig = [
    { blocks: postBlocks,       heightProportion: 1.35 },
    // { blocks: coloredBlocks,    heightProportion: 1.125 }
];

const resizeBlocks = (blocksConfig) => {
    for (let config of blocksConfig) {
        setBlocksHeight(config.blocks, config.heightProportion);
    }
};

const setBlocksHeight = (blocks, h) => {
    for (let block of blocks) {
        const height = h * block.offsetWidth;
        block.setAttribute('style', `height:${height}px`);
    }
};

resizeBlocks(blocksConfig);

window.addEventListener('resize', () => resizeBlocks(blocksConfig), false);
window.addEventListener('orientationchange', () => resizeBlocks(blocksConfig), false);

document.addEventListener('DOMContentLoaded', function() {
    const routeParams = window.location.href.split('/');
    let countryTag = null;
    if (routeParams[routeParams.length - 2] === 'ubezpieczenia' && routeParams[routeParams.length - 1]) {
        countryTag = routeParams[routeParams.length - 1];
    }

    initData(countryTag);

    if (window.country) {
        document.title = `${window.country.name} - Ubezpieczenia Podróżne - Porównaj i Kup On-line - Gorilo.pl`;
        document.querySelector('.section1__header h1').innerHTML = `Ubezpieczenia turystyczne ${window.country.case}, 29 polis, wybierz i kup on-line w 3 minuty`;
        document.querySelector('.section2 .section__header h2').innerHTML = `${window.country.name}: poznaj nasze artykuły i porady`;
    } else if (countryTag === 'narty') {
        document.title = 'Ubezpieczenia Narciarskie i Snowboardowe - Porównaj i Kup On-line - Gorilo.pl';
        document.querySelector('.section1__header h1').innerHTML = 'Porównaj 29 polis narciarskich i snowboardowych, wybierz i kup on-line w 3 minuty';
    }
}, false);


const initData = (country) => {

    fetchData(1, 2, country)
        .then(postsData => {

            if (postsData.length < 2) {
                fetchData(1, 2 - postsData.length)
                    .then(postsData2 => {
                        for (let pD of postsData2) {
                            postsData.push(pD);
                        }

                        updateBlocksContent(firstPostBlocks, postsData);
                    });
            } else {
                updateBlocksContent(firstPostBlocks, postsData);
            }

            document.querySelector('.section2').classList.remove('section--hidden');
        });

    fetchData(2, 3, country)
        .then(postsData => {

            if (postsData.length < 3) {
                fetchData(2, 3 - postsData.length)
                    .then(postsData2 => {
                        for (let pD of postsData2) {
                            postsData.push(pD);
                        }

                        updateBlocksContent(secondPostBlocks, postsData);
                    });
            } else {
                updateBlocksContent(secondPostBlocks, postsData);
            }

            document.querySelector('.section4').classList.remove('section--hidden');
        });

    fetchLinks(country)
        .then(linksData => {

            if (linksData.length < 4) {
                fetchLinks()
                    .then(linksData2 => {
                        for (let lD of linksData2) {
                            linksData.push(lD);
                        }

                        for (let i = 0; i < linksData.length; i++) {
                            postsLinks[i].setAttribute('href', linksData[i].link);
                            postsLinks[i].querySelector('span').innerHTML = linksData[i].title;
                        }
                    });
            } else {
                for (let i = 0; i < linksData.length; i++) {
                    postsLinks[i].setAttribute('href', linksData[i].link);
                    postsLinks[i].querySelector('span').innerHTML = linksData[i].title;
                }
            }
        });
}

function updateBlocksContent(blocks, postsData) {
    for (let i = 0; i < blocks.length; i++) {
        blocks[i].setAttribute('href', postsData[i].link);

        const blockImage = blocks[i].querySelector('.block__image');
        blockImage.setAttribute('style', `background-image: url('${postsData[i].imageUrl}')`);

        const header = blocks[i].querySelector('h3');
        header.innerHTML = postsData[i].title;
    }
}

// Back to order button

const priceDataStr = window.localStorage.getItem('priceData');
const priceData = priceDataStr ? JSON.parse(priceDataStr) : null;

if (priceData && priceData.createdDate) {
	const orderCreatedDate = new Date( priceData.createdDate );
	const today = new Date();

	if (orderCreatedDate.getDate() == today.getDate() && orderCreatedDate.getMonth() == today.getMonth() && orderCreatedDate.getFullYear() == today.getFullYear()) {
		document.querySelector('.nav__back-to-order').classList.remove('nav__back-to-order--hidden');
	}
}