const body = document.querySelector('body');

// BACKDROP

const backdrop = document.querySelector('#backdrop');

backdrop.addEventListener('click', () => {
    backdrop.classList.remove('backdrop--show');
    body.classList.remove('body--no-scroll');
    drawerWrapper.classList.remove('drawer--show');
    authModalWrapper.classList.remove('auth-modal--show');
});


// DRAWER

const drawerWrapper = document.querySelector('#drawer');
const closeButton = drawerWrapper.querySelector('.drawer__close');

document.querySelector('#menu').addEventListener('click', () => {
    drawerWrapper.classList.add('drawer--show');
    backdrop.classList.add('backdrop--show');
    body.classList.add('body--no-scroll');
});

closeButton.addEventListener('click', () => {
    backdrop.classList.remove('backdrop--show');
    body.classList.remove('body--no-scroll');
    drawerWrapper.classList.remove('drawer--show');
    authModalWrapper.classList.remove('auth-modal--show');
});


// AUTH MODAL

const signInButton = document.querySelector('.nav__sign-in');
const authModalWrapper = document.querySelector('#auth-modal');
const authModalClose = authModalWrapper.querySelector('.auth-modal__close');

if (signInButton) {
    signInButton.addEventListener('click', () => {
        authModalWrapper.classList.add('auth-modal--show');
        backdrop.classList.add('backdrop--show');
        body.classList.add('body--no-scroll');
    });
}

authModalClose.addEventListener('click', () => {
    authModalWrapper.classList.remove('auth-modal--show');
    backdrop.classList.remove('backdrop--show');
    body.classList.remove('body--no-scroll');
});