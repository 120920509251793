let isSignUpMode = false;
const signInForm = document.querySelector('#sign-in-form');
const signUpForm = document.querySelector('#sign-up-form');

// const origin = window.location.href.split('/')[2];

const csrfMeta = document.querySelector('meta[name="csrf-token"]');
let csrf = null;
if (csrfMeta) {
    csrf = csrfMeta.getAttribute('content');
}

const validation = {
    'sign-in-form': {
        email: {
            rules: {
                required: { value: true, errorMessage: 'Podaj email' },
                isEmail: { value: true, errorMessage: 'Email jest niepoprawny' }
            },
            timer: null
        },
        password: {
            rules: {
                required: { value: true, errorMessage: 'Podaj hasło' },
                pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/, errorMessage: 'Hasło musi zawierać 8 znaków, w tym małe i wielkie litery, znak specjalny oraz cyfrę' }
            },
            timer: null
        }
    },
    'sign-up-form': {
        firstname: {
            rules: {
                required: { value: true, errorMessage: 'Podaj imię' }
            },
            timer: null
        },
        lastname: {
            rules: {
                required: { value: true, errorMessage: 'Podaj nazwisko' }
            },
            timer: null
        },
        email: {
            rules: {
                required: { value: true, errorMessage: 'Podaj email' },
                isEmail: { value: true, errorMessage: 'Email jest niepoprawny' }
            },
            timer: null
        },
        password: {
            rules: {
                required: { value: true, errorMessage: 'Podaj hasło' },
                pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/, errorMessage: 'Hasło musi zawierać 8 znaków, w tym małe i wielkie litery, znak specjalny oraz cyfrę' }
            },
            timer: null
        },
        password_confirmation: {
            rules: {
                required: { value: true, errorMessage: 'Powtórz hasło' },
                pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/, errorMessage: 'Hasło musi zawierać 8 znaków, w tym małe i wielkie litery, znak specjalny oraz cyfrę' },
                equalTo: { value: '#password', errorMessage: 'Powtórz hasło' }
            },
            timer: null
        }
    }
}

const switchAuthFormMode = () => {
    console.log('click');
    isSignUpMode = !isSignUpMode;

    signInForm.classList.toggle('form--hidden');
    signUpForm.classList.toggle('form--hidden');
};

// document.querySelectorAll('.switch-auth-form-mode span')
//     .forEach(element => element.addEventListener('click', switchAuthFormMode));

const authFormModeSwitchers = document.querySelectorAll('.switch-auth-form-mode span');
for (let switcher of authFormModeSwitchers) {
    switcher.addEventListener('click', switchAuthFormMode);
}

const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const numericPattern = /^\d+$/;

const validate = (value, rules) => {
    if (rules === undefined)
        return null;

    if (rules.required && rules.required.value && (!value || value.trim() === '')) {
        return rules.required.errorMessage;
    }

    if (rules.minLength && rules.minLength.value && value.trim().length < rules.minLength.value) {
        return rules.minLength.errorMessage;
    }

    if (rules.maxLength && rules.maxLength.value && value.trim().length > rules.maxLength.value) {
        return rules.maxLength.errorMessage;
    }

    if (rules.isEmail && rules.isEmail.value && !emailPattern.test(value)) {
        return rules.isEmail.errorMessage;
    }

    if (rules.pattern && rules.pattern.value && !rules.pattern.value.test(value)) {
        return rules.pattern.errorMessage;
    }

    if (rules.equalTo && rules.equalTo.value && value !== document.querySelector('#password').value) {
        return rules.equalTo.errorMessage;
    }

    return null;
}

[signInForm, signUpForm].forEach(form => form.addEventListener('submit', function(event) {
    event.preventDefault();
    const formId = this.id;
    let isFormValid = true;
    const data = {};

    this.querySelectorAll('input').forEach(input => {
        console.log(input);
        let timer = validation[formId][input.name].timer;
        clearTimeout(timer);
        const isInputValid = validateInput(formId, input);
        isFormValid = isFormValid && isInputValid;
        data[input.name] = input.value;
    });

    if (isFormValid) {
        const action = this.id === 'sign-in-form' ? 'login' : 'register';
        const urlParts = window.location.href.split('/');

        fetch(urlParts[0] + '//' + urlParts[2] + '/' + urlParts[3] + '/' + action, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrf,
                    'X-Requested-Wit': 'XMLHttpRequest'
                }
            }).then(response => {
                return new Promise((resolve, reject) => {
                    response.json()
                        .then(data => {
                            if (response.ok) {
                                resolve(data);
                            } else {
                                reject(data);
                            }
                        });
                });
            }).then(data => {
                window.location.href = data.redirect;
            }).catch(rejectedError => {
                let error = null;
                if (rejectedError.errors) {
                    error = rejectedError.errors;
                } else {
                    error = rejectedError;
                }
                console.log(error);
                for (let key in error) {
                    let invalidInput = null;

                    const inputs = this.querySelectorAll('input');
                    for (let input of inputs) {
                        if (input.name === key) {
                            invalidInput = input;
                            break;
                        }
                    }

                    let errorElement = invalidInput.parentNode.querySelector('p');
                    if (errorElement) {
                        errorElement.innerHTML = Array.isArray(error[key]) ? error[key][0] : error[key]; 
                    } else {
                        errorElement = document.createElement('p');
                        errorElement.innerHTML = Array.isArray(error[key]) ? error[key][0] : error[key]; 
                        invalidInput.parentNode.appendChild(errorElement);
                    }
                }
            });
    }
}));

[signInForm, signUpForm].forEach(form => form.querySelectorAll('input').forEach(input => input.addEventListener('input', evt => {
    const formId = form.id;
    let timer = validation[formId][input.name].timer;
    clearTimeout(timer);
    timer = setTimeout(() => validateInput(formId, input), 1000);
})));

[signInForm, signUpForm].forEach(form => form.querySelectorAll('input').forEach(input => input.addEventListener('blur', evt => {
    const formId = form.id;
    let timer = validation[formId][input.name].timer;
    clearTimeout(timer);
    validateInput(formId, input);
})));

function validateInput(formId, input) {
    const value = input.value;
    const rules = validation[formId][input.name].rules;
    const validationError = validate(value, rules);

    let error = input.parentNode.querySelector('p');
    if (error) {
        error.innerHTML = validationError; 
    } else {
        error = document.createElement('p');
        error.innerHTML = validationError; 
        input.parentNode.appendChild(error);
    }

    return validationError === null;
}